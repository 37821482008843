import React, { useContext } from 'react'
import { ContextLang } from '../context/LanguageContext';
import { ContactContext } from '../context/ContactContext';

function ContactForm() {
    const { contactData, setContactData, handleSubmitMessage } = useContext(ContactContext);
    const { t } = useContext(ContextLang);
    return (
        <div className="tp-contact-wrapper">
            <h3 className="tp-contact-title">{t.sentMessage}</h3>
            <div className="tp-contact-form">
                <form id="contact-form" onSubmit={handleSubmitMessage}>
                    <div className="tp-contact-input-wrapper">
                        <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                                <input
                                    name="name"
                                    id="name"
                                    type="text"
                                    value={contactData.name}
                                    onChange={(e) => setContactData({ ...contactData, name: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="tp-contact-input-title">
                                <label htmlFor="name">{t.yourName}</label>
                            </div>
                        </div>
                        <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                                <input
                                    name="company"
                                    id="company"
                                    type="text"
                                    value={contactData.company}
                                    onChange={(e) => setContactData({ ...contactData, company: e.target.value })}
                                />
                            </div>
                            <div className="tp-contact-input-title">
                                <label htmlFor="company">{t.company}</label>
                            </div>
                        </div>
                        <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                                <input
                                    name="email"
                                    id="email"
                                    type="email"
                                    value={contactData.email}
                                    onChange={(e) => setContactData({ ...contactData, email: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="tp-contact-input-title">
                                <label htmlFor="email">{t.yourEmail}</label>
                            </div>
                        </div>
                        <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                                <input
                                    name="subject"
                                    id="subject"
                                    type="text"
                                    value={contactData.subject}
                                    onChange={(e) => setContactData({ ...contactData, subject: e.target.value })}
                                />
                            </div>
                            <div className="tp-contact-input-title">
                                <label htmlFor="subject">{t.subject}</label>
                            </div>
                        </div>
                        <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                                <textarea
                                    id="message"
                                    name="message"
                                    value={contactData.message}
                                    onChange={(e) => setContactData({ ...contactData, message: e.target.value })}
                                    required />
                            </div>
                            <div className="tp-contact-input-title">
                                <label htmlFor="message">{t.yourMessage}</label>
                            </div>
                        </div>
                    </div>
                    <div className="tp-contact-btn">
                        <button type="submit">{t.sentMessage}</button>
                    </div>
                </form>
                <p className="ajax-response" />
            </div>
        </div>
    )
}

export default ContactForm