import React from 'react';
import Main from '../Layouts/Main';

function TermsAndConditions() {
    return (
        <Main>
            <main className="container my-5">
                <div className="container my-5">
                    <div className="text-center mb-4">
                        <h1 className="display-4">Terms and Conditions</h1>
                        <p className="text-muted">Effective Date: 01.11.2024</p>
                    </div>
                    <section className="mb-5">
                        <h2 className="h4">1. Introduction</h2>
                        <p>
                            These Terms and Conditions of Sale ("Terms") govern the sale of products by ELNAF Oy, a Finnish private limited company ("ELNAF," "we," "us," or "our"), to its customers ("Customer," "you," or "your"). These Terms apply to all sales, whether conducted through our website (<a href="https://elnaf.fi" className="text-decoration-none">elnaf.fi</a>, the "Website") or by other means, unless otherwise expressly agreed in writing.
                        </p>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">2. Definitions</h2>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <strong>Consumer:</strong> A natural person acting for purposes outside their trade, business, craft, or profession, as defined by the Finnish Consumer Protection Act.
                            </li>
                            <li className="list-group-item">
                                <strong>Corporate Customer:</strong> Any legal entity or individual purchasing products for purposes related to their trade, business, craft, or profession.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">3. Scope of Application</h2>
                        <p>
                            These Terms constitute the entire agreement between ELNAF and the Customer regarding the sale of products and supersede all prior or contemporaneous communications and proposals, whether oral or written. These Terms prevail over any conflicting terms proposed by the Customer, unless expressly accepted by ELNAF in writing. Where the Customer is a Consumer, as defined by the Finnish Consumer Protection Act, the mandatory provisions of Finnish consumer protection legislation shall apply and take precedence over any conflicting provisions in these Terms.
                        </p>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">4. Website Use and Intellectual Property</h2>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <strong>Website Use:</strong> You agree to use the Website in compliance with all applicable laws and regulations. You shall not interfere with the proper functioning of the Website or attempt to gain unauthorized access to any systems or data.
                            </li>
                            <li className="list-group-item">
                                <strong>Intellectual Property:</strong> All content on the Website, including but not limited to text, graphics, logos, images, software, and trademarks, is the exclusive property of ELNAF or its licensors and is protected by Finnish and international intellectual property laws. You may not reproduce, distribute, modify, or otherwise use any Website content without our prior written consent.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">5. Order Placement, Acceptance, and Contract Formation</h2>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <strong>Order Placement:</strong> Placing an order constitutes an offer to purchase the selected products under these Terms.
                            </li>
                            <li className="list-group-item">
                                <strong>Order Acceptance:</strong> ELNAF's acceptance of your order is expressly conditioned upon your assent to these Terms. A binding contract is formed upon our issuance of an order confirmation email.
                            </li>
                            <li className="list-group-item">
                                <strong>Order Cancellation:</strong> ELNAF reserves the right to refuse or cancel any order for any reason, including but not limited to product unavailability, errors in pricing or product information, or suspected fraud. In such cases, we will promptly notify you and issue a refund if payment has been processed.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">6. Pricing and Payment</h2>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <strong>Prices:</strong> All prices are quoted in Euros and are exclusive of Value Added Tax (VAT) and shipping costs, unless otherwise stated. Prices are subject to change without notice.
                            </li>
                            <li className="list-group-item">
                                <strong>VAT:</strong> Applicable VAT will be added to the invoice at the prevailing Finnish rate, unless a valid VAT exemption applies.
                            </li>
                            <li className="list-group-item">
                                <strong>Customs Duties and Import Taxes:</strong> The Customer is responsible for any applicable customs duties, import taxes, brokerage fees, or other fees levied by the destination country. These charges are not included in the product price or shipping costs.
                            </li>
                            <li className="list-group-item">
                                <strong>Payment Terms:</strong> Payment terms are as specified in the order confirmation or as otherwise agreed in writing. ELNAF accepts various payment methods, as indicated during the checkout process.
                            </li>
                            <li className="list-group-item">
                                <strong>Secure Payment Processing:</strong> Payments are processed securely through a third-party payment processor. ELNAF does not store your full payment card details.
                            </li>
                            <li className="list-group-item">
                                <strong>Price Adjustments:</strong> ELNAF reserves the right to adjust prices prior to delivery due to significant fluctuations in raw material costs, exchange rates, or other unforeseen circumstances beyond our reasonable control. We will provide reasonable notice of such adjustments. For special order products, 100% prepayment is required. ELNAF may withhold processing until prepayment is received.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">7. Delivery and Risk of Loss</h2>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <strong>Delivery Terms:</strong> Delivery terms are as specified in the order confirmation. Unless otherwise agreed, delivery is Ex Works (EXW) ELNAF's designated location (Incoterms 2020).
                            </li>
                            <li className="list-group-item">
                                <strong>Shipping Costs:</strong> Shipping costs are calculated based on weight, dimensions, and destination and are added to the invoice.
                            </li>
                            <li className="list-group-item">
                                <strong>Delivery Timeframes:</strong> Delivery timeframes are estimates according to carrier companies and are not guaranteed. ELNAF is not liable for delays caused by circumstances beyond our reasonable control.
                            </li>
                            <li className="list-group-item">
                                <strong>Risk of Loss:</strong> Risk of loss and title to the products pass to you upon delivery to the carrier at the designated shipping point.
                            </li>
                            <li className="list-group-item">
                                <strong>Inspection and Acceptance:</strong> Upon receipt of the goods, the Customer must inspect them for any visible defects or discrepancies in the presence of the carrier. Any damage to the packaging or goods, or any discrepancies in the number of packages, must be clearly noted on the delivery document (e.g., waybill or delivery receipt) and reported to ELNAF in writing within three (3) business days of delivery. Failure to properly note damages or discrepancies on the delivery document at the time of delivery may forfeit the Customer's right to claim for such damages or non-conformities later.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">8. Returns and Refunds</h2>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <strong>Return Period:</strong> Customers may return standard, unused products in their original packaging within 14 days of receipt.
                            </li>
                            <li className="list-group-item">
                                <strong>Return to Warehouse:</strong> The returned goods must be received at the ELNAF warehouse within 30 days of the original delivery date.
                            </li>
                            <li className="list-group-item">
                                <strong>Return Authorization:</strong> All returns require prior written authorization from ELNAF. Please contact our customer service to obtain a Return Merchandise Authorization (RMA) number.
                            </li>
                            <li className="list-group-item">
                                <strong>Return Shipping:</strong> The Customer is responsible for all return shipping costs, unless the return is due to ELNAF’s error (e.g., defective or incorrect product).
                            </li>
                            <li className="list-group-item">
                                <strong>Restocking Fee:</strong> A restocking fee of 25% of the purchase price (minimum €30) may apply to returns that are not due to ELNAF's error.
                            </li>
                            <li className="list-group-item">
                                <strong>Refund Process:</strong> Upon receipt and inspection of the returned product, ELNAF will process a refund to the original payment method, less any applicable restocking fees and original shipping costs. Refunds may take several business days to process.
                            </li>
                            <li className="list-group-item">
                                <strong>Exceptions:</strong> Custom-made cables or products specifically ordered for the Customer are only returnable if the delivered product does not match the order and the discrepancy cannot be rectified. Goods returned without prior authorization or not in resalable condition will not be refunded.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">9. Warranty</h2>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <strong>Manufacturer's Warranty:</strong> Many products sold by ELNAF are covered by a manufacturer's warranty. The terms and duration of the warranty vary by product and manufacturer.
                            </li>
                            <li className="list-group-item">
                                <strong>ELNAF Warranty:</strong> If no manufacturer's warranty is provided, ELNAF warrants that the products will conform to the specifications provided for a period of twelve (12) months from the date of delivery. This warranty does not cover normal wear and tear, consumables, or damage caused by improper use or handling.
                            </li>
                            <li className="list-group-item">
                                <strong>Warranty Claims:</strong> To make a warranty claim, please contact us with your order details and a description of the defect. Proof of purchase may be required.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">10. Limitation of Liability</h2>
                        <p>
                            To the fullest extent permitted by applicable law, ELNAF shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or use, arising out of or in connection with the sale of products or the use of the Website. ELNAF’s total liability for any claim arising under these Terms shall not exceed the total amount paid by the Customer for the relevant products.
                        </p>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">11. Force Majeure</h2>
                        <p>
                            ELNAF shall not be liable for any failure or delay in performance of its obligations under these Terms to the extent such failure or delay is caused by a Force Majeure event, including but not limited to acts of God, war, strikes, government regulations, or other events beyond our reasonable control.
                        </p>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">12. Governing Law and Dispute Resolution</h2>
                        <p>
                            These Terms shall be governed by and construed in accordance with the laws of Finland, without regard to its principles of conflicts of law. Any disputes arising out of or in connection with these Terms shall be resolved exclusively by the competent courts of Finland.
                        </p>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">13. Entire Agreement and Amendments</h2>
                        <p>
                            These Terms constitute the entire agreement between ELNAF and the Customer regarding the sale of products. ELNAF reserves the right to modify these Terms at any time by posting the revised Terms on the Website. Your continued use of the Website or purchase of products following such posting constitutes your acceptance of the revised Terms.
                        </p>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">14. Export Control and Compliance</h2>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <strong>Compliance:</strong> The Customer agrees to comply with all applicable laws, regulations, and government orders, including trade restrictions.
                            </li>
                            <li className="list-group-item">
                                <strong>Indemnification:</strong> The Customer agrees to indemnify and hold ELNAF harmless from any damages or losses resulting from non-compliance.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-5">
                        <h2 className="h4">15. Contact Information</h2>
                        <address>
                            ELNAF Oy<br />
                            Punttelintie 27, Kotka, Finland<br />
                            Email: <a href="mailto:sales@elnaf.fi" className="text-decoration-none">sales@elnaf.fi</a><br />
                            Phone: <a href="tel:+358503139854" className="text-decoration-none">+358 50 313 9854</a>
                        </address>
                    </section>
                </div>

            </main>
        </Main>
    );
}

export default TermsAndConditions;
