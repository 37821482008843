import React from 'react'
import Header from './Header'
import Footer from './Footer'
import QuickView from '../components/QuickView'

function Main({ children }) {
    return (
        <div>
            <Header />
                {children}
                <QuickView />
            <Footer />
        </div>
    )
}

export default Main