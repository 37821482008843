import React, { useContext } from 'react'
import { ProductsContext } from '../context/GlobalState'
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';
import { ContextLang } from '../context/LanguageContext';

function Orders() {
    const { basket, localBasket } = useContext(ProductsContext);
    const {t} = useContext(ContextLang)
    const edv = 0.255;
    const totalPrice = basket && basket.reduce((acc, product) => {
        const localBasket = JSON.parse(localStorage.getItem('basket')) || [];

        const productInBasket = localBasket.find(item => item.sku === product.SKU);

        const quantity = productInBasket ? productInBasket.quantity : 1;
        const totalPrice = acc + (product.Price || 0) * quantity;

        return parseFloat((totalPrice).toFixed(2));
    }, 0);
    const totalPriceEdv = parseFloat((totalPrice*edv).toFixed(2));
    const totalAmount = parseFloat((totalPrice + totalPriceEdv).toFixed(2));
    return (
        <div className="tp-checkout-place white-bg">
            <h3 className="tp-checkout-place-title">{t.yourOrder}</h3>
            <div className="tp-order-info-list">
                <ul>
                    {/* header */}
                    <li className="tp-order-info-list-header">
                        <h4>{t.product}</h4>
                        <h4>{t.total}</h4>
                    </li>
                    {/* item list */}
                    {basket && basket.map((product, index) => (

                        <li key={index} className="tp-order-info-list-desc">
                            <Link to={`/product/${slugify(product.name + '-sku-' + product.SKU)}`} target="_blank" title={product.name}><span className='order-product-name'>{product.name}</span> <span className='order-product-quantity'> x {localBasket.find(item => item.sku == product.SKU)?.quantity || 0}</span></Link>
                            <span>€{product.Price}</span>
                        </li>
                    ))}

                    
                    {/* total */}
                    <li className="tp-order-info-list-total">
                        <span>{t.edv}</span>
                        <span>€{totalPriceEdv}</span>
                    </li>
                    <li className="tp-order-info-list-total">
                        <span>{t.total}</span>
                        <span>€{totalAmount}</span>
                    </li>
                </ul>
            </div>
            <div className="tp-checkout-btn-wrapper">
                <button type='submit' className="tp-checkout-btn w-100">{t.placeOrder}</button>
            </div>
        </div>
    )
}

export default Orders