import React, { useContext } from 'react'
import Main from '../Layouts/Main'
import { ContextLang } from '../context/LanguageContext';
import { Link } from 'react-router-dom';
import ContactForm from '../components/ContactForm';

function Contact() {
    const {t} = useContext(ContextLang);
    return (
        <Main>
            <main>
                {/* breadcrumb area start */}
                <section className="breadcrumb__area include-bg text-center pt-95 pb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">{t.keepUs}</h3>
                                    <div className="breadcrumb__list">
                                        <span><Link to="/">{t.home}</Link></span>
                                        <span>{t.contact}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* breadcrumb area end */}
                {/* contact area start */}
                <section className="tp-contact-area pb-100">
                    <div className="container">
                        <div className="tp-contact-inner">
                            <div className="row">
                                <div className="col-xl-9 col-lg-8">
                                    <ContactForm/>
                                </div>
                                <div className="col-xl-3 col-lg-4">
                                    <div className="tp-contact-info-wrapper">
                                        <div className="tp-contact-info-item">
                                            <div className="tp-contact-info-content">
                                                <h5>{t.company}</h5>
                                                <p >Elnaf Oy</p>
                                            </div>
                                        </div>
                                        <div className="tp-contact-info-item">
                                            <div className="tp-contact-info-content">
                                                <h5>{t.businessId}</h5>
                                                <p >3477134-8</p>
                                            </div>
                                        </div>
                                        <div className="tp-contact-info-item">
                                            <div className="tp-contact-info-content">
                                                <h5>{t.emailAddress}</h5>
                                                <p ><a href="mailto:sales@elnaf.fi">sales@elnaf.fi</a></p>
                                            </div>
                                        </div>
                                        <div className="tp-contact-info-item">
                                            <div className="tp-contact-info-content">
                                                <h5>{t.phone}</h5>
                                                <p ><a href="tel:+358503139854">+358 50 3139854</a></p>
                                            </div>
                                        </div>
                                        <div className="tp-contact-info-item">
                                            <div className="tp-contact-info-content">
                                                <h5>{t.postalAdress}</h5>
                                                <p>Puntelintie 27, 48310 KOTKA, FINLAND</p>
                                            </div>
                                        </div>
                                        <div className="tp-contact-info-item">
                                            <div className="tp-contact-info-content">
                                                <h5>EUID</h5>
                                                <p >FIFPRO.3477134-8</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* contact area end */}
                {/* map area start */}
                {/* map area end */}
            </main>
        </Main>

    )
}

export default Contact