import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProductsProvider } from './context/GlobalState';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SiteProvider } from './context/ContextSite';
import { OrderProvider } from './context/OrderContext';
import { LangProvider } from './context/LanguageContext';
import { ContactProvider } from './context/ContactContext';
// import ReactGA from "react-ga4";


// ReactGA.initialize("G-CJ77G7MNG4");
// const PageViewTracker = () => {
//   const location = useLocation();

//   useEffect(() => {
//     ReactGA.send({
//       hitType: "pageview",
//       page: location.pathname,
//       title: document.title,
//     });
//   }, [location]);

//   return null;
// };


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <SiteProvider>
      <LangProvider>
        <ProductsProvider>
          <OrderProvider>
            <ContactProvider>
              <ToastContainer />
              {/* <PageViewTracker /> */}
              <App />
            </ContactProvider>
          </OrderProvider>
        </ProductsProvider>
      </LangProvider>
    </SiteProvider>

  </BrowserRouter >
);

reportWebVitals();
