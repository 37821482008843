import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Catalog from './pages/Catalog';
import { useContext, useEffect } from 'react';
import { ProductsContext } from './context/GlobalState';
import NotFound from './pages/NotFound';
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Wishlist from './pages/Wishlist';
import { ContextSite } from './context/ContextSite';
import Checkout from './pages/Checkout';
import Contact from './pages/Contact';
import TermsAndConditions from './pages/TermsAndConditions';

function App() {
  const {setOpenMenu, setOpenBasket} = useContext(ContextSite);

  const { pathname, search  } = useLocation();
  const storage = JSON.parse(localStorage.getItem('basket')) || [];

  useEffect(() => {
    setOpenBasket(false);
    setOpenMenu(false);
    window.scrollTo(0, 0);
  }, [pathname, search]);
  const { result } = useContext(ProductsContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (result == 0) {
      navigate('/notFound');
    }
  }, [result, navigate]);
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/catalog' element={<Catalog />} />
      <Route path='/notFound' element={<NotFound />} />
      <Route path='/cart' element={<Cart />} />
      <Route path='/wishlist' element={<Wishlist />} />
      <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
      <Route path='/product/:product' element={<ProductDetails />} />

      <Route path='/checkout' element={storage.length > 0 ? <Checkout /> : <Cart />} />
    </Routes>
  );
}

export default App;
