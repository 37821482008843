import React, { useContext } from 'react'
import ProductMini from './ProductMini'
import { ProductsContext } from '../context/GlobalState'
import { Link } from 'react-router-dom';
import { ContextLang } from '../context/LanguageContext';

function BasketRight({ open, setOpen }) {
    const { basket } = useContext(ProductsContext);
    const {t} = useContext(ContextLang)
    const totalPrice = basket && basket.reduce((acc, product) => {
        const localBasket = JSON.parse(localStorage.getItem('basket')) || [];

        const productInBasket = localBasket.find(item => item.sku === product.SKU);

        const quantity = productInBasket ? productInBasket.quantity : 1;
        return parseFloat((acc + (product.Price || 0) * quantity).toFixed(2));
    }, 0);
    return (
        <div className={`cartmini__area ${open ? 'cartmini-opened' : ''}`}>
            <div className="cartmini__wrapper d-flex justify-content-between flex-column">
                <div className="cartmini__top-wrapper">
                    <div className="cartmini__top p-relative">
                        <div className="cartmini__top-title">
                            <h4>{t.shoppingCart}</h4>
                        </div>
                        <div className="cartmini__close">
                            <button type="button" onClick={() => setOpen(false)} className="cartmini__close-btn cartmini-close-btn"><i className="fal fa-times" /></button>
                        </div>
                    </div>
                    {basket && basket.length > 0 ? (

                        <div className="cartmini__widget">
                            {basket && basket.map((product ,index) => (

                                <ProductMini key={index} product={product} />
                            ))}
                        </div>
                    ) : (

                        <div className="cartmini__empty text-center">
                            <img src="/assets/img/product/cartmini/empty-cart.png" alt={t.yourCartIsEmpty} />
                            <p>{t.yourCartIsEmpty}</p>
                            <Link to="/catalog" className="tp-btn">{t.goToShop}</Link>
                        </div>
                    )}
                    {/* for wp */}
                    {/* if no item in cart */}
                </div>
                {totalPrice && totalPrice > 0 && (

                    <div className="cartmini__checkout">
                        <div className="cartmini__checkout-title mb-30">
                            <h4>{t.subtotal}:</h4>
                            <span>€{totalPrice}</span>
                        </div>
                        <div className="cartmini__checkout-btn">
                            <Link to="/cart" className="tp-btn mb-10 w-100">{t.viewCart}</Link>
                            <Link to="/checkout" className="tp-btn tp-btn-border w-100"> {t.checkout}</Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default BasketRight