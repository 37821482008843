import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { ContextLang } from './LanguageContext';

export const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
    const [contactData, setContactData] = useState({
        name: '',
        company: '',
        email: '',
        subject: '',
        message: ''
    });
    const { t } = useContext(ContextLang);

    const handleSubmitMessage = async (e) => {
        e.preventDefault();

        // Form məlumatlarının düzgünlüyünü yoxlayın
        if (contactData.name.length && contactData.email.length && contactData.subject.length && contactData.message.length) {
            try {
                console.log(contactData);
                const response = await axios.post('https://elnaf.fi/new/api/contact.php', contactData);
                const result = response.data;

                switch (result.result) {
                    case 1:
                        setContactData({
                            name: '',
                            company: '',
                            email: '',
                            subject: '',
                            message: ''
                        });
                        toast.success(t.messageSentSuccessfully);
                        break;
                    case 3:
                        toast.warning(t.fillRequiredFieldsC);
                        break;
                    default:
                        toast.error(t.unexpectedError); // Ümumi səhv mesajı əlavə edin
                }

            } catch (error) {
                console.error("Error submitting message:", error);
                toast.error(t.unexpectedError); // Səhv mesajı göstər
            }
        } else {
            toast.warning(t.messageSentSuccessfully);
        }
    };

    return (
        <ContactContext.Provider value={{ contactData, setContactData, handleSubmitMessage }}>
            {children}
        </ContactContext.Provider>
    );
};